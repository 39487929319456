// Generated by ReScript, PLEASE EDIT WITH CARE

import * as NavbarTsx from "./navbar.tsx";

var make = NavbarTsx.Navbar;

var make$1 = NavbarTsx.NavbarDivider;

var NavbarDivider = {
  make: make$1
};

var make$2 = NavbarTsx.NavbarSection;

var NavbarSection = {
  make: make$2
};

var make$3 = NavbarTsx.NavbarItem;

var NavbarItem = {
  make: make$3
};

var make$4 = NavbarTsx.NavbarLabel;

var NavbarLabel = {
  make: make$4
};

var make$5 = NavbarTsx.NavbarSpacer;

var NavbarSpacer = {
  make: make$5
};

export {
  make ,
  NavbarDivider ,
  NavbarSection ,
  NavbarItem ,
  NavbarLabel ,
  NavbarSpacer ,
}
/* make Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as NavbarTsx from "./navbar.tsx";
import * as DropdownTsx from "./dropdown.tsx";

var make = NavbarTsx.Navbar;

var make$1 = DropdownTsx.DropdownButton;

var DropdownButton = {
  make: make$1
};

var make$2 = DropdownTsx.DropdownMenu;

var DropdownMenu = {
  make: make$2
};

var make$3 = DropdownTsx.DropdownDivider;

var DropdownDivider = {
  make: make$3
};

var make$4 = DropdownTsx.DropdownItem;

var DropdownItem = {
  make: make$4
};

var make$5 = DropdownTsx.DropdownLabel;

var DropdownLabel = {
  make: make$5
};

var Dropdown;

export {
  make ,
  Dropdown ,
  DropdownButton ,
  DropdownMenu ,
  DropdownDivider ,
  DropdownItem ,
  DropdownLabel ,
}
/* make Not a pure module */

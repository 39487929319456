// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AvatarTsx from "./avatar.tsx";

var make = AvatarTsx.Avatar;

export {
  make ,
}
/* make Not a pure module */
